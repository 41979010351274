import { TrackingContext } from '@mentimeter/http-clients';
import type { HelpTabT } from '@mentimeter/__app-split-features/help-modal/HelpModal';
import { HelpModal as HelpModalC } from '@mentimeter/__app-split-features/help-modal/HelpModal';

const TABS: HelpTabT[] = [
  'Get started',
  'How to',
  'Help center',
  'Menti Academy',
  'Powerpoint',
];

export const HelpModal = ({ open }: { open: boolean }) => (
  <HelpModalC
    tabs={TABS}
    context={TrackingContext.MyPresentations}
    open={open}
  />
);
