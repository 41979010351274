import { Embed, Box, Button, Text, Heading } from '@mentimeter/ragnar-ui';
import { getMentiAcademyURL } from '@mentimeter/user';
import { trackUser } from '@api/tracking/client';

const MentiAcademy = ({ context }: { context: string }) => {
  const trackGoToAcademy = () => {
    trackUser({
      event: 'Clicked go to academy',
      properties: { context },
    });
  };

  return (
    <Box width="100%">
      <Box width="100%" mb={[3, 4]} mx="auto" maxWidth={600}>
        <Embed
          title="Introducing the Mentimeter academy"
          src="https://www.youtube-nocookie.com/embed/7kPzqgwxWdk"
        />
      </Box>
      <Heading as="h3">Self-paced Mentimeter courses</Heading>
      <Text>
        Do you want to learn how to create awesome, interactive presentations?
        Join our free, online courses in the MentiAcademy! Our courses are
        designed to give you a step-by-step guide on how to get the most our of
        our interactive tool. Certificate included!
      </Text>
      <Button
        size="large"
        mt={3}
        mb={2}
        variant="primary"
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackGoToAcademy}
        href={getMentiAcademyURL(
          '/cart/add_product/1529975?price_id=1901688?utm_source=mentimeter.com&utm_medium=referral&utm_campaign=inapp_help',
        )}
      >
        Go to Academy
      </Button>
    </Box>
  );
};
export default MentiAcademy;
