import * as React from 'react';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  SelectItem,
  Box,
  Tabs,
  Light,
} from '@mentimeter/ragnar-ui';
import { Device } from '@mentimeter/ragnar-device';
import { usePrevious } from '@mentimeter/react-hooks';
import { trackUser } from '@api/tracking/client';
import GettingStarted from './GettingStarted';
import HowTo from './HowTo';
import HelpCenter from './HelpCenter';
import MentiAcademy from './MentiAcademy';
import Powerpoint from './Powerpoint';

export type HelpTabT =
  | 'Get started'
  | 'How to'
  | 'Help center'
  | 'Menti Academy'
  | 'Powerpoint';

export const HELP_TABS_MAP: Record<
  HelpTabT,
  React.ComponentType<{ context: string }>
> = {
  'Get started': GettingStarted,
  'How to': HowTo,
  'Help center': HelpCenter,
  'Menti Academy': MentiAcademy,
  Powerpoint,
};

interface HelpModalProps extends React.ComponentProps<typeof ModalContainer> {
  open: boolean;
  tabs: HelpTabT[];
  context: string;
  activeTab?: HelpTabT | undefined;
  setActiveTab?: ((tab: HelpTabT) => void) | undefined;
  trackOpen?: ((tab: HelpTabT) => void) | undefined;
}

export const HelpModal = ({
  open,
  tabs,
  context,
  activeTab: activeTabControlled,
  setActiveTab: setActiveTabControlled,
  trackOpen,
  ...modalProps
}: HelpModalProps) => {
  const previousOpen = usePrevious(open);
  const [activeTabInternal, setActiveTabInternal] = React.useState<HelpTabT>(
    // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'HelpTabT | undefined' is not ass... Remove this comment to see the full error message
    tabs[0],
  );
  const activeTab = activeTabControlled || activeTabInternal;
  const setActiveTab = setActiveTabControlled || setActiveTabInternal;

  const CurrentComponent = HELP_TABS_MAP[activeTab];

  React.useEffect(() => {
    if (open) {
      // if custom tracking logic for opening the modal is passed
      if (previousOpen === false && trackOpen) {
        trackOpen(activeTab);
      } else {
        trackUser({
          event: 'Opened help tab',
          properties: {
            context,
            tab: activeTab,
            placement: 'Help tab',
          },
        });
      }
    }
  }, [open, previousOpen, trackOpen, context, activeTab]);

  const changeTab = (tab: HelpTabT) => {
    if (tab === activeTab) return;
    setActiveTab(tab);
  };

  // TODO: Remove this Light wrapper when mobile-menu is no longer themed with Primary
  return (
    <Light>
      <ModalContainer withPortal={false} topAligned {...modalProps}>
        <ModalHeader>Help</ModalHeader>
        <ModalBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            columnGap: '16px',
          }}
        >
          <Box mb="space8" width="100%">
            <Device.Match equals={1}>
              <SelectItem
                value={activeTab}
                onChange={(e) => changeTab(e.currentTarget.value as HelpTabT)}
              >
                {tabs.map((tab) => (
                  <SelectItem.Option key={tab} value={tab}>
                    {tab}
                  </SelectItem.Option>
                ))}
              </SelectItem>
            </Device.Match>

            <Device.Match greaterThan={1}>
              <Tabs
                fullWidth={true}
                actions={tabs.map((tab) => ({
                  active: activeTab === tab,
                  onClick: () => changeTab(tab),
                  title: tab,
                  key: tab,
                }))}
              />
            </Device.Match>
          </Box>
          <Box
            flex={1}
            width="100%"
            bg="bg"
            overflow="scroll"
            style={{ flexGrow: 1 }}
          >
            <CurrentComponent context={context} />
          </Box>
        </ModalBody>
      </ModalContainer>
    </Light>
  );
};
