import type { BoxT, ButtonT } from '@mentimeter/ragnar-ui';
import { Box, Button, Heading, Meta } from '@mentimeter/ragnar-ui';

export type HeroT = BoxT & {
  title: string;
  description: string;
  action: ButtonT & {
    key: string;
  };
};

const Hero = ({ title, description, action, ...rest }: HeroT) => {
  return (
    <Box width="100%" alignItems="center" mb={4} {...rest}>
      <Heading>{title}</Heading>
      <Meta>{description}</Meta>
      {action && <Button size="large" mt={[3]} {...action} />}
    </Box>
  );
};

export default Hero;
