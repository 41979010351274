import * as React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';

export interface EmbedT {
  title: string;
  src: string;
  allowFullScreen?: boolean;
}

export class Embed extends React.PureComponent<EmbedT, { loading: boolean }> {
  container = React.createRef<HTMLIFrameElement>();
  override state = { loading: true };

  override componentDidMount() {
    if (this.container.current) {
      this.container.current.addEventListener('load', this.handleLoad);
    }
  }

  UNSAFE_componentWillUnmount() {
    if (this.container.current) {
      this.container.current.removeEventListener('load', this.handleLoad);
    }
  }

  handleLoad = () => {
    this.setState({ loading: false });
  };

  override render() {
    const { loading } = this.state;
    const { src, allowFullScreen, title } = this.props;
    return (
      <Box
        width="100%"
        height="0px"
        pb="56.25%"
        overflow="hidden"
        borderRadius="default"
        bg="neutralWeakest"
        extend={() => ({
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        })}
      >
        {loading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="textWeaker" fontSize="87.5">
              Loading embed...
            </Text>
          </Box>
        )}
        <iframe
          ref={this.container}
          title={title}
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={allowFullScreen}
        />
      </Box>
    );
  }
}
