import {
  Embed,
  Box,
  Link,
  Text,
  SubHeading,
  Meta,
} from '@mentimeter/ragnar-ui';

const HOW_TO_VIDEOS = [
  {
    title:
      'How to Create Your First Mentimeter Presentation - 7 Minute Crash Course Tutorial',
    src: 'https://www.youtube-nocookie.com/embed/on_Ib7SP6Go',
    description:
      'In this 7 minute crash course Oscar will demonstrate how quick and easy it is to sign up with Menti, begin working on your presentation, and test out your work! Toggle between different question types, such as word cloud, multiple choice or Q&A. Make use of content or live engagement slides, and customise them with your choice of text and images.',
  },
  {
    title: 'Can I Use Mentimeter with Powerpoint?',
    src: 'https://www.youtube-nocookie.com/embed/miV0KalOgPA',
    description:
      'Are you a Powerpoint user but love the interactivity Mentimeter brings to your presentations? This video shows how you can combine the two. ',
  },
  {
    title: 'How to Vote on a Mentimeter Presentation - Menti.com',
    src: 'https://www.youtube-nocookie.com/embed/ifwpxabWZuI',
    description:
      'Learn how to join and how to vote on a Mentimeter presentation. This guide will teach you the basics of joining and submitting your vote on a Menti presentation with the help of your smartphone.',
  },
  {
    title: 'How to Create a Survey in Minutes',
    src: 'https://www.youtube-nocookie.com/embed/YhznYPWzU1Y',
    description:
      'In this video, you will learn how to create surveys in no time using Mentimeter.',
  },
  {
    title: 'Introducing Mentimeter Enterprise',
    src: 'https://www.youtube-nocookie.com/embed/9VcLj_G3lz4',
    description:
      'Mentimeter Enterprise encourages an inclusive and interactive environment in your organization through unlimited access to all interactive features with added customization options, ownership, and collaboration.',
  },
];

const HowTo = () => (
  <>
    {HOW_TO_VIDEOS.map((v, i) => (
      <Box
        key={v.title}
        alignItems="stretch"
        flexDirection={['column-reverse', null, 'row-reverse']} // reversed so headings are before videos in a11y tree
        width="100%"
        mt={i !== 0 ? 4 : 0}
      >
        <Box
          ml={[0, null, 3]}
          mt={[3, null, 0]}
          justifyContent="center"
          flex={1}
          width={1}
        >
          <SubHeading as="h3">{v.title}</SubHeading>
          <Meta>{v.description}</Meta>
        </Box>
        <Box width={[1, null, 1 / 2]}>
          <Embed title={v.title} src={v.src} allowFullScreen />
        </Box>
      </Box>
    ))}
    <Box mt={4} width="100%" alignItems="center">
      <Text>
        More video tutorials? Check our{' '}
        <Link
          href="https://www.youtube.com/c/Mentimeter-interact-with-your-audience/playlists"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube channel
        </Link>
      </Text>
    </Box>
  </>
);

export default HowTo;
